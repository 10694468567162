const BREAKPOINT_MOBILE = 510;
const BREAKPOINT_TABLET = 1024;

const BREAKPOINT_MOBILE_CSS = `${BREAKPOINT_MOBILE}px`;
const BREAKPOINT_TABLET_CSS = `${BREAKPOINT_TABLET}px`;

export {
  BREAKPOINT_MOBILE,
  BREAKPOINT_TABLET,
  BREAKPOINT_MOBILE_CSS,
  BREAKPOINT_TABLET_CSS,
};
